<template>
  <div>
    <a-modal
      title="选择摄像机"
      :visible="visible"
      width="94%"
      :centered="true"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <div class="flex" :style="{ height: `${ht}px`, paddingBottom: '20px' }">
        <div class="f-l m-r-md">
          <cameral-route-com
            :cameraTabs="cameraTabs"
            @postCamera="getFilterCamera"
            @routeHandle="toggleIndexHandle"
            :resource="resource"
          />
        </div>
        <div class="flex-r full-height">
          <div
            class="search-box flex flex-one items-center m-b-md text-lightblue flex-wrap"
            v-show="activeMenu === 1"
          >
            <div class="m-r-sm flex items-center m-b-xs">
              <span class="flex-s m-r-xs">类型：</span>
              <a-select
                placeholder="摄像机类型"
                class="search-mx-wd-1"
                size="small"
                v-model="classify"
                @change="selectClassify"
              >
                <a-select-option
                  v-for="item in classifyList"
                  :key="item.codeValue"
                  :value="item.codeValue"
                >
                  {{ item.codeName }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="m-r-sm flex items-center m-b-xs"
              v-show="currentComIndex !== 0 && $g.isDepart"
            >
                <span class="flex-s m-r-xs">省份：</span>
                <a-select
                placeholder="请选择省份"
                size="small"
                v-model="regionCode"
                @change="changeProvince"
                class="search-mx-wd-1"
                v-show="$g.isDepart"
              >
                <a-select-option
                  v-for="item in provinceList"
                  :key="item.regionCode"
                  :value="item.regionCode"
                >
                  {{ item.alias }}
                </a-select-option>
              </a-select>
             </div>
            <div
              class="m-r-sm flex items-center m-b-xs"
              v-show="currentComIndex !== 0"
            >
              <span class="flex-s m-r-xs">路线：</span>
              <a-select
                placeholder="路线"
                class="search-mx-wd-1"
                size="small"
                v-model="roadId"
                @change="selectRoad"
                v-show="currentComIndex !== 0"
              >
                <a-select-option
                  v-for="item in roadList"
                  :key="item.roadId"
                  :value="item.roadId"
                >
                  {{ item.roadCode + ' ' + item.roadName }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="m-r-sm flex items-center m-b-xs"
              v-show="currentComIndex !== 0"
            >
              <span class="flex-s m-r-xs">管辖单位：</span>
              <a-cascader
                :options="organizationList"
                :display-render="displayRender"
                class="search-mx-wd-1"
                size="small"
                v-model="organizationId"
                expand-trigger="hover"
                placeholder="管辖单位"
                change-on-select
                v-show="currentComIndex !== 0"
                @change="selectOrganization"
              />
            </div>
            <div
              class="flex text-white items-center m-r-xs text-lightblue m-b-xs"
            >
              <span class="flex-s m-r-xs">桩号：</span>
              K
              <a-input
                placeholder="桩号起始公里数"
                size="small"
                v-model="kmPileMin"
                class="m-l-xs search-mx-wd-0"
              /><span class="m-l-xs m-r-xs">~</span
              >
              K
              <a-input
                placeholder="桩号终止公里数"
                size="small"
                class="m-l-xs search-mx-wd-0 m-r-sm"
                v-model="kmPileMax"
              />
              <span class="flex-s m-r-xs">名称：</span>
              <a-input
                placeholder="摄像机名称"
                size="small"
                class="m-r-sm search-mx-wd-1"
                v-model="cameraName"
              />
            </div>
            <div class="m-b-xs">
              <a-button
                type="primary"
                class="m-r-sm btn-search"
                size="small"
                @click="searchFormHandle"
              >
                搜索
              </a-button>
              <a-button class="btn-reset" size="small" @click="resetFormHandle">
                重置
              </a-button>
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="fl-menu flex items-center">
              <span
                :class="[{ current: activeMenu === 1 }, 'pointer']"
                @click="clickAllHandle"
                >全选</span
              >
              <span
                :class="[{ current: activeMenu === 2 }, 'pointer']"
                @click="clickSectionHandle"
                >已选（{{ cameralCheckAllTotal }}）</span
              >
            </div>
            <template v-if="false">
              <div class="" v-if="activeMenu === 1">
                <span class="text text-lightblue m-r-sm"
                  >未选共计： {{ total }}，当前已选：{{ checkAllLength }}</span
                >
              </div>
              <div class="" v-else-if="activeMenu === 2">
                <span class="text text-lightblue m-r-sm"
                  >已选共计：{{ cameralCheckAllTotal }}，当前已选：{{
                    checkLength
                  }}</span
                >
              </div>
            </template>
          </div>
          <div class="relative" style="height:calc(100% - 140px);">
            <a-table
              v-if="activeMenu === 1"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                onSelect:onSelectSingleAll,
                getCheckboxProps: getCheckboxStatusHandle,
              }"
              :components="resibleTableHeader"
              :columns="columns"
              :rowKey="(record, index) => record.cameraId"
              :data-source="cameraAllList"
              :pagination="paginationOpt"
              :loading="cameraLoading"
              :scroll="scroll"
              bordered
            >
              <template slot="classify" slot-scope="text">
                {{ text | getClassify }}
              </template>
              <template slot="derection" slot-scope="text">
                {{ text | getDirection }}
              </template>
              <template slot="cameraStatus" slot-scope="text">
                {{ text | getCameraStatus }}
              </template>
            </a-table>
            <a-table
              v-else-if="activeMenu === 2"
              :row-selection="{
                selectedRowKeys: selectedCheckRowKeys,
                onChange: onSelectCheckChange,
              }"
              :components="resibleTableHeader"
              :columns="columnsCheck"
              :rowKey="(record, index) => record.cameraUuId"
              :data-source="finalCheckCameraListMap"
              :pagination="paginationCheckOpt"
              :scroll="scroll"
              bordered
            >
              <template slot="classify" slot-scope="text">
                {{ text | getClassify }}
              </template>
              <template slot="derection" slot-scope="text">
                {{ text | getDirection }}
              </template>
              <template slot="cameraStatus" slot-scope="text">
                {{ text | getCameraStatus }}
              </template>
            </a-table>
          </div>
          <div
            class="flex items-center full-width justify-center m-b-sm btn-select"
          >
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              v-if="activeMenu === 1 && selectedRowKeys.length > 0"
              @click="selectCheckList()"
            >
              选择
            </a-button>
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              v-if="activeMenu === 2 && selectedCheckRowKeys.length > 0"
              @click="deleteCheckList()"
            >
              删除
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
//import CameralRouteCom from '@/components/CameralRoute';
import { mapState, mapActions, mapMutations } from 'vuex';
import { createGuid } from '@/tools';
import ResizableTable from './mixins/resizeableTable';
export default {
  name: 'CameralSelect',
  mixins:[ ResizableTable ],
  props: {
    cameraTabs: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
    },
    resource: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      activeMenu: 1,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        // showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.searchCameraFrom();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.searchCameraFrom();
        },
      },
      paginationCheckOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        // showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationCheckOpt.current = 1;
          this.paginationCheckOpt.defaultPageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationCheckOpt.current = current;
          this.paginationCheckOpt.defaultPageSize = size;
          // this.selectedRowKeys = [];
        },
      },
      columns: [
        {
          title: '排序',
          dataIndex:'cameraIndex',
          customRender: (text, record, index) =>
            `${(this.paginationOpt.current - 1) *
              this.paginationOpt.defaultPageSize +
              index +
              1}`,
          width: 70,
          ellipsis: true,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:140,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          ellipsis: true,
          width:140,
        },
        {
          title: '路线',
          dataIndex: 'roadName',
          key: 'roadName',
          ellipsis: true,
          width:100,
        },
        {
          title: '桩号',
          dataIndex: 'kmHmPile',
          key: 'kmHmPile',
          ellipsis: true,
          width: 90,
        },
        {
          title: '兴趣点',
          dataIndex: 'poiName',
          key: 'poiName',
          ellipsis: true,
          width:140
        },
        {
          title: '所属类别',
          dataIndex: 'classify',
          key: 'classify',
          scopedSlots: { customRender: 'classify' },
          width: 90,
          ellipsis: true,
        },
        {
          title: '方向',
          dataIndex: 'derection',
          key: 'derection',
          scopedSlots: { customRender: 'derection' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'cameraStatus',
          key: 'cameraStatus',
          scopedSlots: { customRender: 'cameraStatus' },
          width: 70,
          ellipsis: true,
        },
      ],
      columnsCheck: [
        {
          title: '排序',
          dataIndex:'cameraIndex',
          customRender: (text, record, index) =>
            `${(this.paginationCheckOpt.current - 1) *
              this.paginationCheckOpt.defaultPageSize +
              index +
              1}`,
          width: 70,
          ellipsis: true,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:140
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          ellipsis: true,
          width:140,
        },
        {
          title: '路线',
          dataIndex: 'roadName',
          key: 'roadName',
          ellipsis: true,
          width:100,
        },
        {
          title: '桩号',
          dataIndex: 'kmHmPile',
          key: 'kmHmPile',
          ellipsis: true,
          width: 90,
        },
        {
          title: '兴趣点',
          dataIndex: 'poiName',
          key: 'poiName',
          ellipsis: true,
          width:140
        },
        {
          title: '所属类别',
          dataIndex: 'classify',
          key: 'classify',
          scopedSlots: { customRender: 'classify' },
          width: 90,
          ellipsis: true,
        },
        {
          title: '方向',
          dataIndex: 'derection',
          key: 'derection',
          scopedSlots: { customRender: 'derection' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'cameraStatus',
          key: 'cameraStatus',
          scopedSlots: { customRender: 'cameraStatus' },
          width: 70,
          ellipsis: true,
        },
      ],
      selectedRowKeys: [],
      selectedRowsAll: [],
      selectedCheckRowKeys: [],
      selectedRowsCheck: [],
      classify: undefined,
      roadId: undefined,
      regionId:undefined,
      organizationId: [],
      kmPileMin: '',
      kmPileMax: '',
      cameraName: '',
      checkAllFlag: false,
      checkAllCheck: false,
      finalCheckCameraList: [],

      // firstPush:true
      ht: 0, //自适应高度
      //收藏参数
      cameraFavoriteFlag: '',
      currentComIndex: -1,
      organizationCurrentId: '',
      scroll: { y: window.innerHeight - 360,x:'100%' },
      regionCode: undefined,
      cameraAllList: [],
      cameraLoading: false,
      cameraGroupId: '',
      folderId: '',
      highwayType: undefined,
      toggleType: '',
    };
  },
  computed: {
    ...mapState({
      // cameraAllList: (state) => state.collect.cameraAllList,
      classifyList: (state) => state.collect.classifyList,
      roadList: (state) => state.collect.roadList,
      organizationList: (state) => state.collect.organizationList,
      provinceList: (state) => state.collect.provinceList,
      cameraCheckList: (state) => state.collect.cameraCheckList,
      total: (state) => state.collect.total,
      groupId: (state) => state.collect.groupId,
      currentComIndexState: (state) => state.collect.currentComIndex,
      treeStru: (state) => state.collect.treeStru,
      cameraTab: (state) => state.collect.cameraTab,
      selectCameraList: (state) => state.collect.selectCameraList,
      // cameraLoading: (state) => state.collect.cameraLoading,
    }),
    checkAllLength() {
      if (this.checkAllFlag) {
        return this.total;
      } else {
        return this.selectedRowKeys.length;
      }
    },
    checkLength() {
      if (this.checkAllCheck) {
        return this.finalCheckCameraList.length;
      } else {
        return this.selectedCheckRowKeys.length;
      }
    },
    cameralCheckAllTotal() {
      return this.finalCheckCameraList.length;
    },
    cameraAllFilterList() {
      let data = this.cameraAllList.filter(
        (item) =>
          !this.finalCheckCameraList
            .map((it) => it.cameraId)
            .includes(item.cameraId)
      );
      return data;
    },
    finalCheckCameraListMap() {
      return this.finalCheckCameraList.map((item) => {
        item.cameraUuId = `${item.cameraId}_${createGuid()}`;
        return item;
      });
    },
    organizationListFormat() {
      return this.organizationList.map((item) => {
        return item;
      });
    },
  },
  components: {
    CameralRouteCom: () => import('@/components/CameralRoute.vue'),
  },
  mounted() {
    //初始化
    this.currentComIndex = this.cameraTabs === 'collect' ? 1 : 0;
    this.getClassifyList('CLASSIFY');
    this.getProvince({ regionLevel: 1 });
    this.getRoadList();
    this.getOrganationList();
    this.setFixHt();
    // this.searchCameraFrom();fixsxy
  },
  watch: {
    regionCode(nv, ov) {
      if (nv && nv !== ov) {
        this.selectDownInfo(nv);
      }
    },
    /* groupId(nv) {
      this.cameraGroupId = nv;
    }, */

    visible(n, o) {
      if (n && n !== o) {
        this.initData();

        this.setFixHt();

        this.searchCameraFrom();
        this.getProvince({ regionLevel: 1 });
        this.getRoadList();
        this.getOrganationList();
      }
    },
  },
  methods: {
    ...mapMutations([
      'setCameraAllList',
      'setSelectCameraList',
      'setCurrentComIndex',
      'setTreeStru',
      'setGroupId',
      'setCameraTab',
    ]),
    ...mapActions([
      'getCameraListByCondition',
      'getClassifyList',
      'getRoadList',
      'getOrganationList',
      'getProvince',
    ]),
    initData() {
      this.currentComIndex = this.cameraTabs === 'collect' ? 1 : 0;
      if (this.currentComIndex === 1) {
        this.cameraFavoriteFlag = 1;
      } else {
        this.cameraFavoriteFlag = '';
      }
      this.finalCheckCameraList = this.selectCameraList;
    },
    toggleIndexHandle(index) {
      this.currentComIndex = index;
      this.getProvince({ regionLevel: 1 });
      this.getRoadList();
      this.getOrganationList();
    },
    setFixHt() {
      let doc =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.ht = doc - 100;
    },
    getFilterCamera(camera) {
      console.log('hahaha', camera);
      if (camera.highwayType === 0 || camera.highwayType === 1) {
        this.highwayType = camera.highwayType;
      }
      //过滤摄像机
      if (camera.needType === 'regions') {
        this.regionCode = camera.check ? camera.regionCodes : '';
        this.roadId = camera.check ? camera.roadId : undefined;
        this.organizationCurrentId = camera.check ? camera.organizationId : '';
      }
      if (camera.needType === 'region') {
        this.regionCode = camera.check ? camera.regionCode : '';
        this.roadId = camera.check ? camera.roadId : undefined;
        this.organizationCurrentId = camera.check ? camera.organizationId : '';
      }
      if (camera.needType === 'organizationArea') {
        this.organizationCurrentId = camera.check ? camera.organizationId : '';
        this.roadId = camera.check ? camera.roadId : undefined;
        this.regionCode = camera.check
          ? camera.regionCodes
            ? camera.regionCodes
            : camera.regionCode
          : '';
      }
      if (camera.needType === 'roadArea') {
        this.roadId = camera.check ? camera.roadId : '';
        this.regionCode = camera.check
          ? camera.regionCodes
            ? camera.regionCodes
            : camera.regionCode
          : '';
        this.organizationCurrentId = camera.check ? camera.organizationId : '';
      }
      if (camera.needType === 'group') {
        this.cameraGroupId = camera.groupId;
      }
      if (camera.needType === 'collect') {
        this.cameraFavoriteFlag = 1;
        this.folderId = camera.collectId;
      }
      if (camera.needType === 'none') {
        this.paginationOpt.current = 1;
        this.paginationOpt.defaultPageSize = 10;
        this.regionCode = undefined;
        // this.organizationCurrentId = '';fix之前
        this.organizationCurrentId = camera.organizationId
          ? camera.organizationId
          : '';
        this.roadId = undefined;
        this.cameraGroupId = '';
        this.cameraFavoriteFlag = '';
        this.folderId = '';
        this.highwayType = undefined;
      }
      this.searchFormHandle();
    },
    // eslint-disable-next-line no-unused-vars
    getCheckboxStatusHandle(record) {
      // console.log('haha', this.finalCheckCameraList);
      let selectCheckKeys = this.finalCheckCameraList.map(
        (item) => item.cameraId
      );
      return {
        props: {
          disabled: selectCheckKeys.includes(record.cameraId),
          defaultChecked: selectCheckKeys.includes(record.cameraId),
        },
      };
    },

    handleCancel() {
      this.resetData();
      this.$emit('closeCameralDialog');
    },
    resetData() {
      //清空所有摄像机
      this.setCameraAllList([]);
      //清空已选摄像机

      this.finalCheckCameraList = [];
      this.selectedCheckRowKeys = [];
      this.selectedRowsCheck = [];
      this.selectedRowKeys = [];
      this.selectedRowsAll = [];
      this.activeMenu = 1;

      //清除桩号
      this.kmPileMin = '';
      this.kmPileMax = '';
      //恢复分页为1
      this.paginationOpt.current = 1;
      this.paginationCheckOpt.current = 1;
      this.cameraGroupId = '';
      //清空search参数
      // this.resetSearchParams();fix
      //设置tab为不存在的，便于再次打开重新复制，触发联动
      // this.setCurrentComIndex(-1);
      // this.setCameraTab('');
      this.currentComIndex = -1;
      //清空
      //清空初始状态值
      this.cancelReset();
      // this.resetFormHandle();
      this.toggleType = '';
      this.regionCode = undefined;
      this.organizationId = [];
      this.roadId = undefined;
    },
    cancelReset() {
      /* this.paginationOpt.current = 1;
      this.paginationCheckOpt.current = 1; */
      this.cameraFavoriteFlag = '';
      this.folderId = '';
      this.classify = undefined;
      this.highwayType = undefined;
    },
    searchFormHandle() {
      this.paginationOpt.current = 1;
      this.paginationCheckOpt.current = 1;
      this.toggleType = '';
      this.searchCameraFrom();
    },
    resetFormHandle() {
      this.classify = undefined;
      this.roadId = undefined;
      this.organizationId = [];
      this.organizationCurrentId = '';
      this.kmPileMin = '';
      this.kmPileMax = '';
      this.cameraName = '';
      this.paginationOpt.current = 1;
      this.paginationCheckOpt.current = 1;
      this.getProvince({ regionLevel: 1 });
      this.getRoadList();
      this.getOrganationList();
      this.toggleType = '';
      this.regionCode = undefined;
      this.searchCameraFrom();
    },
    resetSearchParams() {
      this.setTreeStru(null);
      this.setGroupId('');
      this.cameraFavoriteFlag = '';
    },
    searchCameraFrom() {
      /*  let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        cameraGroupId: this.cameraGroupId,
        organizationId: this.organizationCurrentId,
        roadCode: this.roadId,
        classifyCode: this.classify,
        kmPileMin: this.kmPileMin,
        kmPileMax: this.kmPileMax,
        cameraName: this.cameraName,
        cameraFavoriteFlag: this.cameraFavoriteFlag,
      };fix以前 */
      let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        cameraGroupId: this.cameraGroupId,
        organizationId: this.organizationCurrentId,
        roadCode: this.roadId,
        regionId: this.regionCode,
        classifyCode: this.classify,
        kmPileMin: this.kmPileMin,
        kmPileMax: this.kmPileMax,
        cameraName: this.cameraName,
        cameraFavoriteFlag: this.cameraFavoriteFlag,
        folderId: this.folderId,
        highwayType: this.highwayType,
      };
      this.setCameraAllList([]);
      /* this.getCameraListByCondition(obj).then((res) => {
        if (res.code === 200) {
          this.paginationOpt.total = res.total;
        }
      }); */
      this.cameraAllList = [];
      this.cameraLoading = true;
      this.$api.collect.getCameraListByCondition(obj).then((res) => {
        if (res.code === 200) {
          this.cameraAllList = res.data;
          this.paginationOpt.total = res.total;
          this.cameraLoading = false;
        }
      });
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = [...new Set(selectedRowKeys)];
      this.selectedRowsAll = this.deRepeat(
        this.selectedRowsAll.concat(selectedRows)
      );
      //  console.log('haha',selectedRowKeys,selectedRows,this.selectedRowKeys,this.selectedRowsAll);
      // console.log('选择前', this.selectedRowsAll);
    },
    onSelectSingleAll(selectedRow, bool) {
      if(!bool){
        this.selectedRowsAll.splice(this.selectedRowsAll.indexOf(selectedRow),1);
      }
    },
    deRepeat(arr) {
      let map = {};
      arr.forEach((item) => {
        if (!map[item.cameraId]) {
          map[item.cameraId] = item;
        }
      });
      return Object.values(map);
    },
    onSelectCheckChange(selectedRowKeys, selectedRows) {
      this.selectedCheckRowKeys = selectedRowKeys;
      this.selectedRowsCheck = selectedRows;
    },

    selectClassify(e) {
      this.classify = e;
    },
    selectRoad(e) {
      this.roadId = e;
      //联动
      this.toggleType = 'road';
      if(this.$g.isDepart) {
        let tempRegionCode = this.regionCode;
        this.regionCode = undefined;
        this.getProvince({ roadId: e,commitFlag : false }).then((res) => {
          // console.log(res)
          if (res.code == 200) {
            // this.regionCode = res.data?.length?res.data[0].regionCode:undefined;
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionCode = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
    },
    selectOrganization(value,options) {
      //console.log(value,options);
      this.organizationId = value;
      this.organizationCurrentId =
        this.organizationId.length > 0
          ? this.organizationId[this.organizationId.length - 1]
          : '';
      
      //联动
      this.toggleType = 'organization';
     
      if(this.organizationCurrentId === options[0].regionCode) {
        this.organizationId = [];
        return;
      }
      let tempRegionCode = this.regionCode;
      this.regionCode = undefined;
      if(this.$g.isDepart) {
        this.getProvince({
          organizationId: value?.length > 0 && value[value.length - 1],
          commitFlag : false
        }).then((res) => {
          if (res.code == 200) {
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionCode = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectCheckList() {
      let finalCheckKeys = this.finalCheckCameraList.map(
        (item) => item.cameraId
      );
      let bool = true;
      let arr = [];

      let selectedKeys = this.selectedRowKeys;
      console.log('selectedKeys', selectedKeys)
      if (selectedKeys.length >= finalCheckKeys.length) {
        bool = true;
      } else {
        for (let j = 0; j < finalCheckKeys.length; j++) {
          if (!selectedKeys.includes(finalCheckKeys[j])) {
            bool = false;
            break;
          }
        }
      }
      /* debugger;
      console.log('选择后', this.selectedRowsAll); */
      if (bool) {
        arr = this.finalCheckCameraList.concat(
          this.selectedRowsAll.filter(
            (item) => !finalCheckKeys.includes(item.cameraId)
          )
        );
      } else {
        arr = this.finalCheckCameraList.filter((item) =>
          selectedKeys.includes(item.cameraId)
        );
      }

      this.finalCheckCameraList = arr;

      this.paginationCheckOpt.total = this.finalCheckCameraList.length;
      this.paginationCheckOpt.current = 1;

      //清空选择状态结束
      this.setSelectCameraList(this.finalCheckCameraList);
      this.$emit('sureCamera', this.finalCheckCameraList);
      //添加toast提示
      this.$message.success('添加摄像机成功');
    },

    deleteCheckList() {
      this.$confirm({
        title: '提示',
        content: '你确定删除所选摄像机吗?',
        onOk: () => {
          this.finalCheckCameraList = this.finalCheckCameraList.filter(
            (val) => {
              return this.selectedRowsCheck.indexOf(val) === -1;
            }
          );
          this.selectedRowsAll = this.selectedRowsAll.filter((val) => {
            return this.finalCheckCameraList.indexOf(val) !== -1;
          });
          this.selectedRowKeys = this.finalCheckCameraList.map(
            (item) => item.cameraId
          );

          this.paginationCheckOpt.total = this.finalCheckCameraList.length;
          this.paginationCheckOpt.current = 1;

          //清空选择状态开始
          this.selectedCheckRowKeys = [];
          this.selectedRowsCheck = [];
          this.setSelectCameraList(this.finalCheckCameraList);
          this.$emit('sureCamera', this.finalCheckCameraList);
        },
        onCancel: () => {},
      });
    },
    //点击全部
    clickAllHandle() {
      this.activeMenu = 1;
      //清除状态
      // this.selectedRowKeys = [];
      // this.selectedRowsAll = [];fix之前

      this.paginationOpt.current = 1;
      this.searchFormHandle();
      if (this.$g.isDepart) {
        this.searchCameraFrom();
      }
    },
    //点击部分
    clickSectionHandle() {
      /*  console.error(this.selectedRowKeys, this.selectedCheckRowKeys); */
      this.activeMenu = 2;
      //清除状态
      this.selectedCheckRowKeys = [];
      this.selectedRowsCheck = [];
      this.paginationCheckOpt.total = this.finalCheckCameraList.length;
      this.paginationCheckOpt.current = 1;
    },
    changeProvince(val) {
      this.regionCode = val;
      //联动组织单位和路线
      this.getRoadList({ regionCode: val });
      this.getOrganationList({ regionCode: val });
      /* this.roadId = undefined;
      this.organizationId = []; */
    },
    selectDownInfo(nv) {
      if (this.toggleType == 'road') {
        this.getOrganationList({ regionCode: nv });
      } else if (this.toggleType == 'organization') {
        this.getRoadList({ regionCode: nv });
      }
    },
    /*  toggleCurrentIndex(index) {
      this.setCameraAllList([]);
      this.currentComIndex = index;
      this.activeMenu = 1;
      this.paginationOpt.current = 1;
       fixsxy添加逻辑开始
      if (this.currentComIndex === 0) {
        //清空收藏和组;
        this.cameraFavoriteFlag = '';
        this.cameraGroupId = '';
        this.regionId = '';
        this.organizationId = [];
        this.organizationCurrentId =
          this.treeStru && this.treeStru.organizationId;
        this.roadCode = this.treeStru && this.treeStru.roadCode;
      } else if (this.currentComIndex === 1) {
        this.cameraFavoriteFlag = 1;
        //清空树状调阅和组
        this.regionId = '';
        this.organizationId = [];
        this.organizationCurrentId = '';
        this.regionId = '';
        this.cameraGroupId = '';
      } else if (this.currentComIndex === 2) {
        //清空树状调阅和收藏
        this.regionId = '';
        this.organizationId = [];
        this.organizationCurrentId = '';
        this.regionId = '';
        this.cameraFavoriteFlag = '';
        this.cameraGroupId = this.groupId;
      }
      fixsxy添加逻辑结束
      this.searchCameraFrom();
    }, */
  },
};
</script>

<style scoped>
.f-l {
  width: 320px;
}
.flex-r{
  width:calc(100% - 320px);
}
.text {
  bottom: 26px;
}
.wd {
  width: 40%;
}
.btn-select {
  position: relative;
  bottom: 4px;
}

</style>
